(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/store/assets/javascripts/reducers/result-modifier-slice.js') >= 0) return;  svs.modules.push('/components/tipsen/store/assets/javascripts/reducers/result-modifier-slice.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  createEntityAdapter,
  createSlice
} = RTK;
const {
  trackEvent
} = svs.components.analytics;
const {
  getDisplayName
} = svs.utils.products;
const resultModifierAdapter = createEntityAdapter();
const resultModifierSlice = createSlice({
  name: 'ResultModifier',
  initialState: resultModifierAdapter.getInitialState(),
  reducers: {
    setAlternativeOutcomes: (state, action) => {
      const {
        wagerId,
        alternativeOutcomes
      } = action.payload;
      resultModifierAdapter.upsertOne(state, {
        id: wagerId,
        alternativeOutcomes
      });
    },
    clearAlternativeOutcomes: (state, action) => {
      const {
        wagerId
      } = action.payload;
      resultModifierAdapter.upsertOne(state, {
        id: wagerId,
        alternativeOutcomes: []
      });
    },
    setMaskedOutcomes: (state, action) => {
      var _wager$maskedOutcomes;
      const {
        wagerId,
        maskedOutcomes,
        isExamined,
        eventCount,
        productId
      } = action.payload;
      const wager = state.entities[wagerId];
      const maskedOutcomesCount = (wager === null || wager === void 0 || (_wager$maskedOutcomes = wager.maskedOutcomes) === null || _wager$maskedOutcomes === void 0 ? void 0 : _wager$maskedOutcomes.length) || 0;
      resultModifierAdapter.upsertOne(state, {
        id: wagerId,
        maskedOutcomes
      });
      if (!isExamined && !maskedOutcomes.length) {
        trackEvent({
          category: 'Mina spel',
          action: 'correct_all',
          opt_label: getDisplayName(productId),
          opt_value: eventCount - maskedOutcomesCount
        });
      }
    },
    clearMaskedOutcomes: (state, action) => {
      const {
        wagerId
      } = action.payload;
      resultModifierAdapter.upsertOne(state, {
        id: wagerId,
        maskedOutcomes: []
      });
    },
    deleteModifiedResult: (state, action) => {
      const {
        wagerId
      } = action.payload;
      resultModifierAdapter.removeOne(state, wagerId);
    },
    unmaskEventOutcome: (state, action) => {
      var _wager$maskedOutcomes2, _wager$maskedOutcomes3;
      const {
        wagerId,
        eventIndex,
        eventCount,
        productId,
        isExamined
      } = action.payload;
      const wager = state.entities[wagerId];
      const maskedOutcomesCount = (_wager$maskedOutcomes2 = wager === null || wager === void 0 || (_wager$maskedOutcomes3 = wager.maskedOutcomes) === null || _wager$maskedOutcomes3 === void 0 ? void 0 : _wager$maskedOutcomes3.length) !== null && _wager$maskedOutcomes2 !== void 0 ? _wager$maskedOutcomes2 : 0;
      if (wager && wager.maskedOutcomes) {
        wager.maskedOutcomes = wager.maskedOutcomes.filter(index => index !== eventIndex);
      }
      if (!isExamined && maskedOutcomesCount && maskedOutcomesCount === eventCount) {
        trackEvent({
          category: 'Mina spel',
          action: 'Smygen',
          opt_label: getDisplayName(productId)
        });
      }
    }
  }
});
const selectResultModifierById = (state, wagerId) => resultModifierAdapter.getSelectors(state => state.ResultModifier).selectById(state, wagerId) || {};
const selectMaskedOutcomes = (state, wagerId) => {
  const wager = resultModifierAdapter.getSelectors(state => state.ResultModifier).selectById(state, wagerId);
  return wager === null || wager === void 0 ? void 0 : wager.maskedOutcomes;
};
const selectAlternativeOutcomes = (state, wagerId) => {
  const wager = resultModifierAdapter.getSelectors(state => state.ResultModifier).selectById(state, wagerId);
  return wager === null || wager === void 0 ? void 0 : wager.alternativeOutcomes;
};
setGlobal('svs.components.tipsen.store.reducers.resultModifier', {
  reducer: resultModifierSlice.reducer,
  actions: resultModifierSlice.actions,
  selectors: _objectSpread(_objectSpread({}, resultModifierAdapter.getSelectors(state => state.ResultModifier)), {}, {
    selectResultModifierById,
    selectMaskedOutcomes,
    selectAlternativeOutcomes
  })
});

 })(window);